import { useState, useEffect, useContext } from "react";

import { Box, Button, Typography, useMediaQuery, useTheme, styled } from "@mui/material";

import { formatUnits } from "viem";

import httpClient from "../../utils/http-client";
import { UserDetailsContext } from "../../contexts/UserContext";
import { colors, blackGradient } from "../../utils/colors";

import Template from "./Templates/UserPageTemplate";
import TableTemplate from "./Templates/TableTemplate";

interface Transaction {
  createdAt: string;
  id: string;
  amount: string;
  multiplier: number;
  won: boolean;
  winnings: string;
}

const ShadowedLine = styled('div')({
  boxShadow: '0px -2px 6px 0px rgba(236, 95, 89, 0.6)',
  padding: '0px',
  borderBottom: '2px solid #ec5f59',
  width: '16px',
  transition: 'opacity 0.5s ease'
});

const columns = [
  {
    id: 'date', label: 'Date', renderCell: (row: Transaction) => (
      <Typography
        sx={{ color: colors.textWhite, fontWeight: 500, opacity: 0.85 }}
      >
        {row.createdAt}
      </Typography>
    )
  },
  {
    id: 'amount', label: 'Amount', renderCell: (row: Transaction) => (
      <Typography
        sx={{ color: colors.textWhite, fontWeight: 700 }}
      >
        {Number(formatUnits(BigInt(row.amount), 18)).toFixed(2)}
      </Typography>
    )
  },
  {
    id: 'multiplier', label: 'Multiplier', renderCell: (row: Transaction) => (
      <Typography
        sx={{ color: row.won? colors.lightGreen : colors.pink, fontWeight: 700 }}
      >
        {row.multiplier.toFixed(2)}x
      </Typography>
    )
  },
  {
    id: 'payout', label: 'Payout', renderCell: (row: Transaction) => (
      <Typography
        sx={{ color: row.won? colors.lightGreen : colors.pink, fontWeight: 700 }}
      >
        {Number(formatUnits(BigInt(row.winnings), 18)).toFixed(2)}
      </Typography>
    )
  },
];

const tables = [
  { label: 'Dice', path: 'dice' },
  { label: 'Blackjack', path: 'blackjack' },
];

export default function BetHistory() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTable, setSelectedTable] = useState(tables[0]);
  const [currentRows, setCurrentRows] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1)
  const [hasNext, setHasNext] = useState(true);

  const { address, userJWT } = useContext(UserDetailsContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const maxRows = 20

  useEffect(() => {
    if (!address || !userJWT) return;
    const getHistory = async () => {
      if (address && userJWT && hasNext && currentRows.length < currentPage * maxRows) {
        setIsLoading(true);
        // maxRows + 1 to check if there are more rows
        const response = await httpClient.get(`${selectedTable.path}/history?limit=${maxRows + 1}&offset=${(currentPage - 1) * maxRows}`,);
        if (response.data.length < maxRows + 1) {
          setHasNext(false);
        }
        setCurrentRows(prev => [...prev, ...response.data.slice(0, maxRows)]);
        setIsLoading(false);
      }
    };
    getHistory()
  }, [address, userJWT, currentPage, selectedTable]);

  const handleButtonClick = (label: string) => {
    setIsLoading(true);
    setCurrentRows([]);
    setHasNext(true);
    if (currentPage > 1) setCurrentPage(1);
    setSelectedTable(tables.find(table => table.label === label) || tables[0]);
  };

  return (
    <Template>
      <Box sx={{ width: '100%', p: '8px', backgroundColor: colors.blackRussian, backgroundImage: blackGradient, borderRadius: '8px', minHeight: '512px' }}>
        <Box sx={{ display: 'flex', gap: '32px' }}>
          {tables.map((button) => (
            <Button
              key={button.label}
              variant="text"
              onClick={() => handleButtonClick(button.label)}
              sx={{
                flexDirection: 'column',
              }}
            >
              <Typography variant="body1"
                sx={{
                  fontWeight: 600,
                  textTransform: 'none',
                  color: "#FFF",
                  paddingBottom: '2px',
                  opacity: selectedTable.label === button.label ? 1 : 0.5
                }}>
                {button.label}
              </Typography>
              <ShadowedLine sx={{ opacity: selectedTable.label === button.label ? 1 : 0 }} />
            </Button>
          ))}
        </Box>
        <Box sx={{ mt: '16px' }}>
          {currentRows.length > 0 || isLoading ?
            <TableTemplate
              columns={isSmallScreen? [columns[0], ...columns.slice(2)] : columns}
              rows={currentRows}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isLoading={isLoading}
              hasNext={currentRows.length >= currentPage * maxRows}
              maxRows={maxRows}
            />
            : <Typography variant="h5" sx={{ color: '#fff', opacity: 0.75 }}>
              No data to display
            </Typography>
          }
        </Box>
      </Box>
    </Template>
  );
}
