import { useContext, useCallback } from "react";
import { Button, Box, styled } from "@mui/material";

import { BlackjackContext, Card } from "../../../../contexts/BlackjackContext";
import { UserDetailsContext } from "../../../../contexts/UserContext";
import { colors } from '../../../../utils/colors';
import { HitIcon, StandIcon, DoubleDownIcon, SplitIcon } from "./GameButtonIcons";
import { hit, stand, doubleDown, split } from "../../../../api/blackjack";
import { showToast, TOASTTYPE } from "../../../../utils/toast";

export enum Action {
  Hit = "Hit",
  Stand = "Stand",
  Double = "Double",
  Split = "Split",
  Deal = "Deal",
  Bust = "Bust",
  Full = "Full",
  Blackjack = "Blackjack",
  Ace = "Ace",
}

export const stopActions = [
  Action.Stand.toString(),
  Action.Bust.toString(),
  Action.Full.toString(),
  Action.Blackjack.toString(),
  Action.Double.toString(),
  Action.Ace.toString()
];

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  justifyContent: 'center',
  backgroundColor: colors.grey800,
  '&:hover': {
    backgroundColor: colors.freshGrey
  },
  overflow: 'hidden',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  color: colors.textWhite,
  '&:disabled': {
    opacity: 0.5,
    color: colors.freshWhite,
  },
  fontWeight: 700,
}))

const CustomButton = ({ children, callback, disabled }: { children: React.ReactNode, callback: () => Promise<any>, disabled?: boolean }) => {
  const { gameActive, setGameActive,
    isLoading, setIsLoading,
    setScores,
    updateLastCard,
    dealerHand,
    setCardAndFlip,
    setCurrentHandNumber,
    cardSetTimeout,
    animationTimout,
    currentHandNumber
  } = useContext(BlackjackContext)
  const { setFormattedBalance } = useContext(UserDetailsContext)

  const onClick = useCallback(async () => {
    try {
      setIsLoading(true)
      const game = await callback()

      if (
        game.state.player.length > 1 &&
        game.state.player.length - 1 > currentHandNumber &&
        (game.state.player[currentHandNumber].value >= 21 ||
          game.state.player[currentHandNumber].actions.some((action: string) => stopActions.includes(action)))
      ) {
        setCurrentHandNumber(prev => prev + 1);
      }

      // open dealer cards if there are more than 1 from backend
      if (!game.active && game.state.dealer.cards.length > 1) {
        const secondCard = game.state.dealer.cards[1] as Card
        updateLastCard({
          ...secondCard,
          id: dealerHand[1].id,
          side: 'front',
          color: ['H', 'D'].includes(secondCard.suit) ? 'red' : 'black'
        }, 'dealer', 0)

        const timeoutPromises = game.state.dealer.cards.slice(2).map((card: any, index: number) => {
          return new Promise((resolve) => {
            setTimeout(() => {
              setCardAndFlip(card, 'dealer', 0, true);
            }, (index + 1) * cardSetTimeout);
            setTimeout(() => {
              resolve(1);
            }, (index + 1) * (cardSetTimeout + animationTimout));
          });
        });
        await Promise.all(timeoutPromises);
      }

      setScores((prev) => ({
        dealer: [game.state.dealer.value],
        player: game.state.player.map((hand: any) => hand.value),
      }));

      setFormattedBalance(game.balance)


      if (game.active !== gameActive) {
        setGameActive(game.active)
      }
    } catch (error: any) {
      const errorMessage = typeof error?.response?.data?.message === 'string' ? error.response.data.message : error.message;
      showToast(TOASTTYPE.error, errorMessage);
    }
    setIsLoading(false)

  }, [setIsLoading, callback, currentHandNumber, setScores, animationTimout, gameActive, setGameActive, setFormattedBalance, setCurrentHandNumber, updateLastCard, dealerHand, cardSetTimeout, setCardAndFlip])

  return (
    <StyledButton
      onClick={onClick}
      disabled={!gameActive || isLoading || disabled}
    >
      {children}
    </StyledButton>
  )
}

const Hit = () => {
  const { currentHandNumber, setCardAndFlip } = useContext(BlackjackContext)
  const callback = async () => {
    const game = await hit()
    setCardAndFlip(game.state.player[currentHandNumber].cards.slice(-1)[0] as Card, 'player', currentHandNumber, true)
    return game
  }

  return (
    <CustomButton
      callback={callback}
    >
      Hit <HitIcon sx={{ fontSize: '14px', ml: '6px', color: colors.yellow }} />
    </CustomButton>
  )
}

const Stand = () => {
  const callback = async () => {
    const game = await stand()

    return game
  }

  return (
    <CustomButton
      callback={callback}
    >
      Stand <StandIcon sx={{ fontSize: '14px', ml: '6px', color: colors.pink }} />
    </CustomButton>
  )
}

const Split = () => {
  const { setCardAndFlip, setPlayerHand, playerHand, maxSplits, currentHandNumber, betAmount } = useContext(BlackjackContext)
  const { balance } = useContext(UserDetailsContext);
  const callback = async () => {
    setPlayerHand((prev) => {
      const newHands = [...prev];
      const currentHand = newHands[currentHandNumber];

      newHands.splice(currentHandNumber, 1, [currentHand[0]]);
      newHands.push([currentHand[1]]);
      return newHands;
    });
    const game = await split()

    setCardAndFlip(game.state.player[currentHandNumber].cards[1] as Card, 'player', currentHandNumber, true)
    setCardAndFlip(game.state.player[game.state.player.length - 1].cards[1] as Card, 'player', game.state.player.length - 1, true)
    return game
  }

  const disabled = playerHand.length - 1 >= maxSplits ||
    playerHand.length < 1 ||
    playerHand[currentHandNumber].length !== 2 ||
    (playerHand[currentHandNumber][0].rank !== playerHand[currentHandNumber][1].rank) ||
    balance.includes('?') || Number(balance.replace(' MCOQ', '')) < betAmount * 2

  return (
    <CustomButton
      callback={callback}
      disabled={disabled}
    >
      Split <SplitIcon sx={{ fontSize: '14px', ml: '6px', color: colors.textWhite }} />
    </CustomButton>
  )
}

const DoubleDown = () => {
  const { currentHandNumber, setCardAndFlip, betAmount } = useContext(BlackjackContext)
  const { balance } = useContext(UserDetailsContext);

  const callback = async () => {
    const game = await doubleDown()
    setCardAndFlip(game.state.player[currentHandNumber].cards.slice(-1)[0] as Card, 'player', currentHandNumber, true)

    return game
  }

  const disabled = balance.includes('?') || Number(balance.replace(' MCOQ', '')) < betAmount * 2

  return (
    <CustomButton
      callback={callback}
      disabled={disabled}
    >
      Double Down <DoubleDownIcon sx={{ fontSize: '14px', ml: '6px', color: colors.textWhite }} />
    </CustomButton>
  )
}

// const Insurance = ({ insurance_, setInsuranceUsed }: { insurance_: boolean, setInsuranceUsed: any }) => {
//   const callback = async () => {
//     await insurance(insurance_ ? 'insurance' : 'noInsurance')
//     setInsuranceUsed(true)
//   }
//   return (
//     <StyledButton
//       onClick={callback}
//     >
//       {insurance_ ? 'Insurance' : 'No Insurance'}
//     </StyledButton>
//   )

// }

const GameButtons = () => {
  // const { dealerHand } = useContext(BlackjackContext)
  // const [insuranceUsed, setInsuranceUsed] = useState(false)
  return (
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: '2fr 2fr',
      gap: '8px',
      width: '100%',
      my: '16px',
    }}>
      {/* {dealerHand[0][0] && dealerHand[0][0].rank === 'A' && !insuranceUsed ?
        <>
          <Insurance insurance_={true} setInsuranceUsed={setInsuranceUsed} />
          <Insurance insurance_={false} setInsuranceUsed={setInsuranceUsed} />
        </>
        :
        <>
          <Hit />
          <Stand />
          <Split />
          <DoubleDown />
        </>
      } */}
      <Hit />
      <Stand />
      <Split />
      <DoubleDown />
    </Box>
  )
}

export default GameButtons